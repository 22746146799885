import { StorageItem } from '../apis/client-axios';
import { STATUS_ITEM_MANAGEMENT } from './constants';

export const Helper = {
  getSourceFile: (source?: string, defaultSrc?: string) =>
    source
      ? process.env.REACT_APP_API_URL + '/assets/get-by-path/' + source
      : defaultSrc
        ? defaultSrc
        : '/assets/icons/avatar/default-avatar.png',
};

export const renderStatus = (itemDetail: StorageItem): string => {
  let currentStatus: string = '';

  // storage request cancel
  if (itemDetail?.storageRequest?.status === STATUS_ITEM_MANAGEMENT.CANCELED)
    currentStatus = STATUS_ITEM_MANAGEMENT.STORAGE_REQUEST_CANCELED;
  else {
    if (
      itemDetail?.status === STATUS_ITEM_MANAGEMENT.CANCELED ||
      itemDetail?.status === STATUS_ITEM_MANAGEMENT.TEMPORARY_CANCELED
    ) {
      currentStatus = itemDetail?.status;
    }
    // storage request not cancel
    else if (itemDetail?.status === STATUS_ITEM_MANAGEMENT.NOT_DELIVERED) {
      if (itemDetail?.storageRequest?.status === STATUS_ITEM_MANAGEMENT.NOT_SHIPPED)
        currentStatus = STATUS_ITEM_MANAGEMENT.NOT_SHIPPED;
      if (itemDetail?.storageRequest?.status === STATUS_ITEM_MANAGEMENT.IN_TRANSIT)
        currentStatus = STATUS_ITEM_MANAGEMENT.IN_TRANSIT;
    } else if (
      // đang có shipToBuyerRequest | shipBackSellerRequest | disposalRequest k cancel
      itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
      itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
      itemDetail?.disposalRequest?.status
    ) {
      currentStatus =
        itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
        itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
        itemDetail?.disposalRequest?.status;

      if (itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status === 'SHIPPED') {
        currentStatus = STATUS_ITEM_MANAGEMENT.SHIP_TO_BUYER_SHIPPED;
      }
      if (itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.shippoShippingLabel?.trackingStatus === 'DELIVERED') {
        currentStatus = STATUS_ITEM_MANAGEMENT.SHIP_TO_BUYER_DELIVERED;
      }
    } else {
      currentStatus = itemDetail?.status;
      // INSTORAGE
      if (itemDetail?.status === STATUS_ITEM_MANAGEMENT.IN_STORAGE) {
        currentStatus = STATUS_ITEM_MANAGEMENT.IN_STORAGE;
        if (Boolean(itemDetail?.inStorageRefundFlag) === false) {
          currentStatus = STATUS_ITEM_MANAGEMENT.IN_STORAGE_NOT_REFUNDED;
        } else if (Boolean(itemDetail?.inStorageRefundFlag) === true) {
          currentStatus = STATUS_ITEM_MANAGEMENT.INSTORAGE_REFUNDED;
        }
      }
    }

    return currentStatus;
  }

  // if (itemDetail?.storageRequest?.status === STATUS_ITEM_MANAGEMENT.REQUEST_CANCEL)
  //   currentStatus = STATUS_ITEM_MANAGEMENT.REQUEST_CANCEL;
  // else if (
  //   itemDetail?.status === STATUS_ITEM_MANAGEMENT.NOT_DELIVERED &&
  // ) {
  //   currentStatus = STATUS_ITEM_MANAGEMENT.NOT_SHIPPED;
  // } else if (
  //   itemDetail?.status === STATUS_ITEM_MANAGEMENT.NOT_DELIVERED &&
  //   itemDetail?.storageRequest?.status === STATUS_ITEM_MANAGEMENT.IN_TRANSIT
  // ) {
  //   currentStatus = STATUS_ITEM_MANAGEMENT.IN_TRANSIT;
  // } else if (
  //   itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
  //   itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
  //   itemDetail?.disposalRequest?.status
  // ) {
  //   currentStatus =
  //     itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
  //     itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
  //     itemDetail?.disposalRequest?.status;
  // } else {
  //   currentStatus = itemDetail?.status;
  // }

  return currentStatus;
};
