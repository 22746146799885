export function SvgTaskActive() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6668 1.16797H9.3335V5.83464H18.6668V1.16797Z" fill="white" />
      <path
        d="M3.5 3.5H7V8.16667H21V3.5H24.5V13.6197C22.7543 12.7906 20.7713 12.6075 18.9033 13.1029C17.0354 13.5982 15.4036 14.7399 14.2981 16.325C13.1926 17.9101 12.6851 19.8359 12.8657 21.7599C13.0463 23.684 13.9034 25.4817 15.2845 26.8333H3.5V3.5Z"
        fill="white"
      />
      <path
        d="M14.5835 20.9987C14.5835 20.156 14.7495 19.3217 15.0719 18.5431C15.3944 17.7646 15.8671 17.0573 16.4629 16.4614C17.0587 15.8656 17.7661 15.3929 18.5446 15.0705C19.3231 14.748 20.1575 14.582 21.0002 14.582C21.8428 14.582 22.6772 14.748 23.4557 15.0705C24.2342 15.3929 24.9416 15.8656 25.5374 16.4614C26.1333 17.0573 26.6059 17.7646 26.9284 18.5431C27.2509 19.3217 27.4168 20.156 27.4168 20.9987C27.4168 22.7005 26.7408 24.3326 25.5374 25.536C24.3341 26.7393 22.702 27.4154 21.0002 27.4154C19.2984 27.4154 17.6663 26.7393 16.4629 25.536C15.2595 24.3326 14.5835 22.7005 14.5835 20.9987ZM23.8165 22.1654L22.1668 20.5157V18.376H19.8335V21.4817L22.1668 23.815L23.8165 22.1654Z"
        fill="white"
      />
    </svg>
  );
}

export function SvgTaskInActive() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16667 1.16797H19.8333V3.5013H24.5V12.8346H22.1667V5.83464H19.8333V8.16797H8.16667V5.83464H5.83333V24.5013H12.8333V26.8346H3.5V3.5013H8.16667V1.16797ZM10.5 5.83464H17.5V3.5013H10.5V5.83464Z"
        fill="white"
      />
      <path
        d="M21.0002 16.9154C19.9172 16.9154 18.8786 17.3456 18.1128 18.1113C17.347 18.8771 16.9168 19.9157 16.9168 20.9987C16.9168 22.0817 17.347 23.1203 18.1128 23.8861C18.8786 24.6518 19.9172 25.082 21.0002 25.082C22.0831 25.082 23.1217 24.6518 23.8875 23.8861C24.6533 23.1203 25.0835 22.0817 25.0835 20.9987C25.0835 19.9157 24.6533 18.8771 23.8875 18.1113C23.1217 17.3456 22.0831 16.9154 21.0002 16.9154ZM14.5835 20.9987C14.5835 20.156 14.7495 19.3217 15.0719 18.5431C15.3944 17.7646 15.8671 17.0573 16.4629 16.4614C17.0587 15.8656 17.7661 15.3929 18.5446 15.0705C19.3231 14.748 20.1575 14.582 21.0002 14.582C21.8428 14.582 22.6772 14.748 23.4557 15.0705C24.2342 15.3929 24.9416 15.8656 25.5374 16.4614C26.1333 17.0573 26.6059 17.7646 26.9284 18.5431C27.2509 19.3217 27.4168 20.156 27.4168 20.9987C27.4168 22.7005 26.7408 24.3326 25.5374 25.536C24.3341 26.7393 22.702 27.4154 21.0002 27.4154C19.2984 27.4154 17.6663 26.7393 16.4629 25.536C15.2595 24.3326 14.5835 22.7005 14.5835 20.9987ZM22.1668 18.376V20.5157L23.8165 22.1654L22.1668 23.815L19.8335 21.4817V18.376H22.1668Z"
        fill="white"
      />
    </svg>
  );
}
