import { Avatar, Menu, MenuProps } from 'antd';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../../apis/client-axios';
import { MEMBER_PERMISSIONS, USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../../constants/router';
import { RootState, useAppDispatch } from '../../../store';
import { logout } from '../../../store/authSlice';
import { Helper } from '../../../util/helper';
import { socketConfig } from '../../../webSocket/webSocket.config';
import './style.scss';
import { MainAppContext } from '../../App/MainApp';
import { disableMenu } from '../../../util/menu';

type MenuItem = Required<MenuProps>['items'][number];

const AccountInfo = (props: { infoDropdownItems?: MenuProps['items'] }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [avatar, setAvatar] = useState<Asset | undefined>(undefined);
  // const [isOpen, setIsOpen] = useState(false);
  const { isOpen, setIsOpen } = useContext(MainAppContext);

  useEffect(() => {
    if (authUser) {
      setAvatar(authUser?.avatar);
    }
  }, [authUser]);

  const userItem: MenuItem[] = [
    {
      key: '0',
      // icon: <MailOutlined />,
      onClick: () => setIsOpen(true),
      label: (
        <Avatar
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      ),
      children: [
        {
          key: '1',
          label: 'ユーザ管理',
          children: [
            {
              key: '1-1',
              label: '個人設定',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PROFILE, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PROFILE);
                }
              },
            },
            {
              key: '1-2',
              label: 'ebay管理',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.EBAY_MANAGEMENT, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EBAY_MANAGEMENT);
                }
              },
            },
            {
              key: '1-3',
              label: 'スタッフ管理',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.MEMBER_MANAGEMENT, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.MEMBER_MANAGEMENT);
                }
              },
            },
            // {
            //   key: '1-4',
            //   label: '一般設定',
            //   onClick: (e) => {
            //     if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
            //       window.open(USER_ROUTE_NAME.SETTING_MANAGEMENT, '_blank');
            //     } else {
            //       navigate(USER_ROUTE_NAME.SETTING_MANAGEMENT);
            //     }
            //   },
            // },
            {
              key: '1-4',
              label: '通知設定',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.SETTING_NOTIFICATION, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.NOTIFY_SETTING);
                }
              },
            },
            {
              key: '1-5',
              label: '返送ラベル発行設定',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.Authenticated),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.FEDEX, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.FEDEX);
                }
              },
            },
          ],
        },
        {
          key: '2',
          label: 'プラン・お支払い管理',
          children: [
            {
              key: '2-1',
              label: '料金プラン変更',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PLAN_SETTING, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PLAN_SETTING);
                }
              },
            },
            {
              key: '2-2',
              label: 'お支払い方法',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.CARD, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EDIT_CARD);
                }
              },
            },
          ],
        },
        {
          key: '3',
          label: 'ご利用料金確認',
          children: [
            {
              key: '3-2',
              label: (
                <div className="ant-menu-sub-item">
                  <span className="ant-menu-sub-item-text">ご利用料金確認</span>
                  <span className="ant-menu-sub-item-des">月ごとの集計、商品ごとの集計、領収書発行</span>
                </div>
              ),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PAYMENT_LIST_HISTORY, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.PAYMENT_LIST_HISTORY);
                }
              },
            },
          ],
        },
        {
          key: '4',
          label: '退会手続き',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.QUIT_MEMBERSHIP, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.QUIT_MEMBERSHIP);
            }
          },
        },
        {
          key: '5',
          label: 'サポート',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.SUPPORT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.SUPPORT);
            }
          },
        },
        {
          key: '6',
          label: <span className="logout">ログアウト</span>,
          onClick: () => {
            socketConfig.disconnect();
            dispatch(logout());
            localStorage.removeItem('token');
            localStorage.removeItem('authUser');
          },
        },
      ],
    },
  ];
  const subUserItem: MenuItem[] = [
    {
      key: '0',
      // icon: <MailOutlined />,
      onClick: () => setIsOpen(true),
      label: (
        <Avatar
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      ),
      children: [
        {
          key: '1',
          label: 'ユーザ管理',
          children: [
            {
              key: '1-1',
              label: '個人設定',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.Authenticated),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PROFILE, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PROFILE);
                }
              },
            },
            {
              key: '1-2',
              label: 'ebay管理',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.AccountManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.EBAY_MANAGEMENT, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EBAY_MANAGEMENT);
                }
              },
            },
            {
              key: '1-3',
              label: 'スタッフ管理',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.AccountManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.MEMBER_MANAGEMENT, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.MEMBER_MANAGEMENT);
                }
              },
            },
            // {
            //   key: '1-4',
            //   label: '一般設定',
            //   disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.Setting),
            //   onClick: (e) => {
            //     if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
            //       window.open(USER_ROUTE_NAME.SETTING_MANAGEMENT, '_blank');
            //     } else {
            //       navigate(USER_ROUTE_NAME.SETTING_MANAGEMENT);
            //     }
            //   },
            // },
            {
              key: '1-4',
              label: '通知設定',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.Setting),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.SETTING_NOTIFICATION, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.NOTIFY_SETTING);
                }
              },
            },
          ],
        },
        {
          key: '2',
          label: 'プラン・お支払い管理',
          children: [
            {
              key: '2-1',
              label: '料金プラン変更',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PLAN_SETTING, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PLAN_SETTING);
                }
              },
            },
            {
              key: '2-2',
              label: 'お支払い方法',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.CARD, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EDIT_CARD);
                }
              },
            },
          ],
        },
        {
          key: '3',
          label: 'ご利用料金確認',
          children: [
            {
              key: '3-2',
              label: (
                <div className="ant-menu-sub-item">
                  <span className="ant-menu-sub-item-text">ご利用料金確認</span>
                  <span className="ant-menu-sub-item-des">月ごとの集計、商品ごとの集計、領収書発行</span>
                </div>
              ),
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PAYMENT_LIST_HISTORY, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.PAYMENT_LIST_HISTORY);
                }
              },
            },
          ],
        },
        // {
        //   key: '4',
        //   label: '退会手続き',
        //   disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
        //   onClick: () => navigate(USER_ROUTE_NAME.QUIT_MEMBERSHIP),
        // },
        {
          key: '5',
          label: <span className="logout">ログアウト</span>,
          onClick: () => {
            socketConfig.disconnect();
            dispatch(logout());
            localStorage.removeItem('token');
            localStorage.removeItem('authUser');
          },
        },
      ],
    },
  ];

  return (
    <div className={`mx-3 cursor-pointer menu-profile`}>
      {isOpen ? (
        <Menu
          mode="inline"
          defaultOpenKeys={['0']}
          triggerSubMenuAction="hover"
          onSelect={() => setIsOpen(false)}
          onOpenChange={(value: string[]) => setIsOpen(value.includes('0'))}
          items={authUser?.parentId ? subUserItem : userItem}
        />
      ) : (
        <Avatar
          onClick={() => setIsOpen(true)}
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      )}
    </div>
  );
};

export default memo(AccountInfo);
