import { Modal, ModalProps } from 'antd';
import './style.scss';
import CustomButton from '../buttons/CustomButton';

interface customModalProps extends ModalProps {
  className?: string;
  isOpen: boolean;
  handleCancelModal: () => void;
  isLink?: boolean;
  children?: React.ReactNode;
}

const ModalError = (props: customModalProps) => {
  const { isOpen, className, handleCancelModal, isLink, children, ...restProps } = props;

  const handleOpenChatSupport = () => {
    document?.getElementById('btn-chat-support-id')?.click();
    handleCancelModal();
  };

  return (
    <Modal
      className={`errors-modal ${className}`}
      open={isOpen}
      onCancel={handleCancelModal}
      closeIcon={false}
      centered
      {...restProps}
      footer={[]}
    >
      <div className="title">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M8.00015 11.1916C8.12506 11.1916 8.22947 11.1494 8.31338 11.065C8.39777 10.9806 8.43996 10.8759 8.43996 10.751C8.43996 10.6266 8.39777 10.5222 8.31338 10.4378C8.22947 10.3534 8.12506 10.3112 8.00015 10.3112C7.87524 10.3112 7.77083 10.3534 7.68693 10.4378C7.60302 10.5222 7.56082 10.6266 7.56035 10.751C7.55987 10.8755 7.60206 10.9801 7.68693 11.065C7.77083 11.1494 7.87524 11.1916 8.00015 11.1916ZM7.64259 8.8259H8.35772V4.53511H7.64259V8.8259ZM8.0023 14.4368C7.11267 14.4368 6.27597 14.2681 5.49218 13.9305C4.70888 13.5925 4.02736 13.1339 3.44762 12.5546C2.86789 11.9753 2.40901 11.2945 2.07099 10.5122C1.73297 9.72983 1.56396 8.89336 1.56396 8.00279C1.56396 7.11221 1.73274 6.27551 2.07028 5.49267C2.40782 4.70984 2.86646 4.02832 3.44619 3.44811C4.02593 2.8679 4.70673 2.40903 5.48861 2.07148C6.27049 1.73394 7.10695 1.56493 7.99801 1.56445C8.88906 1.56398 9.72577 1.73275 10.5081 2.07077C11.2905 2.40879 11.972 2.86742 12.5527 3.44668C13.1334 4.02594 13.5922 4.70674 13.9293 5.4891C14.2664 6.27145 14.4354 7.10792 14.4363 7.9985C14.4373 8.88907 14.2685 9.72578 13.93 10.5086C13.5915 11.2914 13.1329 11.973 12.5541 12.5532C11.9753 13.1334 11.2945 13.5923 10.5117 13.9298C9.72887 14.2673 8.8924 14.4364 8.0023 14.4368Z"
            fill="#F43258"
          />
        </svg>
        <div className="content">
          {children}
          {isLink && (
            <div className="link" onClick={handleOpenChatSupport}>
              <span>問い合わせ</span>
            </div>
          )}
        </div>
      </div>
      <CustomButton onClick={handleCancelModal} className="custom-button__cancel">
        閉じる
      </CustomButton>
    </Modal>
  );
};

export default ModalError;
