import { useMutation, useQuery } from '@tanstack/react-query';
import { Dropdown, Image, Layout, MenuProps, Row, theme } from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { bizNotificationsApi, ebayManagerApi, systemNotificationUserApi, taskApi, userApi } from '../../apis';
import { BizNotification, MaskAsRead } from '../../apis/client-axios';
import SvgGreenDot from '../../components/@svg/SvgGreenDot';
import SvgLogoHome from '../../components/@svg/SvgLogoHome';
import SvgMessage from '../../components/@svg/SvgMessage';
import SvgNotification from '../../components/@svg/SvgNotification';
import SvgNotificationFocus from '../../components/@svg/SvgNotificationFocus';
import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../constants/router';
import { updateMe } from '../../store/authSlice';
import { NavigateBiz, QUERY_KEY, TIME_FORMAT } from '../../util/constants';
import { socketConfig } from '../../webSocket/webSocket.config';
import { ChannelRegister } from '../../webSocket/webSocket.constants';
import AccountInfo from './Account/AccountInfo';
import './style.scss';
import { SvgTaskActive, SvgTaskInActive } from '../../components/@svg/SvgTask';

const { Header } = Layout;

const Topbar = (props: {
  collapsed?: boolean;
  onCollapsed?: Function;
  infoDropdownItems?: MenuProps['items'];
  children?: ReactNode;
}) => {
  const size = useRef(20);
  const location = useLocation();
  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: false,
    delay: 100,
  });

  const navigate = useNavigate();
  const settings = useRef([
    USER_ROUTE_NAME.EBAY_MANAGEMENT,
    USER_ROUTE_NAME.MEMBER_MANAGEMENT,
    USER_ROUTE_NAME.SETTING_MANAGEMENT,
  ]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { data } = useQuery({
    queryKey: [QUERY_KEY.USER_ME],
    queryFn: () => userApi.userControllerCustomerMe(),
  });
  const [messageCount, setMessageCount] = useState(0);
  const [systemNotiCount, setSystemNotiCount] = useState(0);
  const [taskCount, SetTaskCount] = useState(0);
  const selectedKeys = location.pathname.substring(1);
  const [page, setPage] = useState(1);
  const [bizReponse, setBizReponse] = useState(0);
  const [lsData, setLsData] = useState<BizNotification[]>([]);
  const [itemRead, setItemRead] = useState<BizNotification>(undefined);

  const { data: dataCountSysNoti, refetch } = useQuery({
    queryKey: [QUERY_KEY.COUNT_USER_UNREAD_SYSTEM_NOTIFICATION],
    queryFn: () => systemNotificationUserApi.notifiUserControllerCount(),
  });
  const { data: countTask } = useQuery({
    queryKey: ['countTask'],
    queryFn: () =>
      taskApi.todoListControllerTodoList(1, 1, null, null, 'OPEN').then(({ data: { total = 0 } }) => {
        SetTaskCount(total);
      }),
  });

  const { data: BizNotify, refetch: refetchBiz } = useQuery({
    queryKey: [QUERY_KEY.BIZ_NOTIFY, page],
    queryFn: () =>
      bizNotificationsApi.bizNotificationControllerGetAll(page, size?.current).then((data) => {
        // const noti = data?.data?.content?.map((item, index) => {
        //   return RenderItem(item, index + 1 === +data?.data?.content?.length) as any;
        // });
        if (page > 1) {
          setLsData((prev) => [...prev, ...data?.data?.content]);
        } else {
          setLsData(data?.data?.content);
        }
        setMessageCount(data?.data?.total);
        setBizReponse(data?.data?.content?.length);
      }),
  });

  const { data: listAccountExpired } = useQuery({
    queryKey: ['listAccountExpired'],
    queryFn: () => ebayManagerApi.ebayManagerControllerGetAllAccountEbayExpiredRefreshToken(),
  });

  const BizNotifyMutation = useMutation({
    mutationFn: (dto: MaskAsRead) =>
      bizNotificationsApi.bizNotificationControllerMaskAsRead(dto).then((res) => res?.data),
    onSuccess: () => {
      refetchBiz();
      setLsData((prev) => prev.map((i) => (i?.id === itemRead?.id ? { ...i, isRead: false } : i)));
    },
    onError: (error) => {},
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(updateMe(data.data));
    }
  }, [data]);
  useEffect(() => {
    let count: any = dataCountSysNoti?.data;
    if (Number(count?.unread) > 0) {
      setSystemNotiCount(Number(count?.unread) || 0);
    } else {
      setSystemNotiCount(0);
    }
  }, [dataCountSysNoti]);

  useEffect(() => {
    if (inView && bizReponse >= size?.current) {
      setPage((prev) => prev + 1);
    }
  }, [inView]);

  const handleLogoClick = (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(USER_ROUTE_PATH.RETURN_MANAGEMENT, '_blank');
    } else {
      navigate(`/${USER_ROUTE_NAME.RETURN_MANAGEMENT}`);
    }
  };

  useEffect(() => {
    socketConfig.on(ChannelRegister.SYSTEM, (data) => {
      const parseData = JSON.parse(data);
      if (parseData?.type == ChannelRegister.SYSTEM && parseData?.content) {
        refetch();
        setSystemNotiCount((current) => current + 1);
      }
    });

    socketConfig.on(ChannelRegister.BIZ_NOTIFICATION, (data) => {
      const parseData = JSON.parse(data);
      if (parseData?.content) {
        setMessageCount((prev) => prev + 1);
        setLsData((prev) => {
          return [parseData?.content, ...prev];
        });
      }
    });
  }, []);

  const Decoration = [
    'UnsubcriptionEvent',
    'CardUpdatedEvent',
    'MemberAddedEvent',
    'MemberDeletedEvent',
    'ItemReceivedEvent',
    'ItemShippedEvent',
    'NewInquiryMessageEvent',
    'StorageRequestAutoCancelEvent',
  ];
  const environment = process.env.REACT_APP_ENVIRONMENT == 'staging';
  return (
    <Header
      style={{ padding: 0, background: colorBgContainer, height: '60px' }}
      className="d-flex justify-content-between header"
    >
      <Row className="header-logo" onClick={(e) => handleLogoClick(e)} style={{ cursor: 'pointer' }}>
        <SvgLogoHome />
        {environment && <h2>staging</h2>}
        {listAccountExpired?.data?.length ? (
          <div className="header-expired-list">
            <span>認証トークンの期限が切れています。</span>
            <span>ebayアカウント管理画面で「再連携」ボタンを押して更新してください。</span>
            <span>アカウント名：{listAccountExpired?.data?.map((v) => ` ${v.name}`).join(',')}</span>
          </div>
        ) : null}
      </Row>
      <Row className="header-right">
        <div className="task" onClick={() => navigate(USER_ROUTE_NAME.TASK_MANAGEMENT)}>
          {selectedKeys === USER_ROUTE_NAME.TASK_MANAGEMENT ? <SvgTaskActive /> : <SvgTaskInActive />}
          <div className="task-count">{taskCount === 0 ? '' : taskCount > 99 ? '99+' : taskCount}</div>
        </div>
        <Dropdown
          trigger={['click']}
          rootClassName="dropdown-menu-biz ebay__scroll-bar-custom"
          // menu={{ items: lsData?.map((item, index) => RenderItem(item, index + 1 === +lsData?.length) as any), className: 'biz-drop' }}
          menu={{
            items: lsData?.map((item, index) => {
              let content: string | ReactNode = (item?.metadata as any)?.content;
              // if (item?.eventType === 'ShipRequestCompletedEvent' && item?.content?.includes('$')) {
              //   const [text, value] = item?.content?.split('$');
              //   content = (
              //     <>
              //       <span style={{ color: '#f43258' }}>{text.substring(0, text.length - 1)}</span>: ${value}
              //     </>
              //   );
              // }
              if (Decoration.includes(item?.eventType)) {
                content = (
                  <>
                    <span className="text-decoration">{content}</span>
                  </>
                );
              }
              return {
                key: item?.id,
                label: (
                  <div className="bix-drop-item" ref={index + 1 === +lsData?.length ? ref : null}>
                    <div className="bix-drop-item-image">
                      <Image
                        sizes="64px"
                        preview={false}
                        className="rouded"
                        src={
                          (item?.metadata as any)?.assetLink
                            ? (item?.metadata as any)?.assetLink
                            : '/assets/logo/system-logo.png'
                        }
                      />
                    </div>
                    <div className="bix-drop-item-info">
                      <div className="bix-drop-item-info-title">{(item?.metadata as any)?.title}</div>
                      <div className="bix-drop-item-info-sku">{content}</div>
                      <div className="bix-drop-item-info-date">
                        {dayjs(item?.createdAt).format(TIME_FORMAT.DATE('/'))}
                      </div>
                    </div>
                    {item?.isRead !== true && (
                      <div className="bix-drop-item-status">
                        <SvgGreenDot />
                      </div>
                    )}
                  </div>
                ),
                onClick: () => {
                  if (!item?.isRead) {
                    setItemRead(item);
                    BizNotifyMutation.mutate({ bizId: item?.id });
                  }
                  if (NavigateBiz?.[item?.eventType]) {
                    const redirect = NavigateBiz?.[item?.eventType](+(item?.metadata as any)?.entityId || undefined);
                    window.open(redirect, '_blank');
                    // navigate(redirect);
                  }
                },
              };
            }),
            className: 'biz-drop',
          }}
          placement="bottomRight"
          arrow={false}
          // open={true}
          className="header-right-biz"
        >
          <div className="header-right-biz-message cursor-pointer">
            <SvgMessage />
            <div className="header-right-biz-message-number">
              {messageCount == 0 ? '' : messageCount > 99 ? '99+' : messageCount}
            </div>
          </div>
        </Dropdown>
        <div className="header-right-notifications" onClick={() => navigate(USER_ROUTE_NAME.SYSTEM_NOTIFICATION)}>
          {selectedKeys === USER_ROUTE_NAME.SYSTEM_NOTIFICATION ? <SvgNotificationFocus /> : <SvgNotification />}
          <div className="header-right-notifications-number">
            {systemNotiCount == 0 ? '' : systemNotiCount > 99 ? '99+' : systemNotiCount}
          </div>
        </div>
        <AccountInfo infoDropdownItems={props.infoDropdownItems} />
      </Row>
    </Header>
  );
};

export default Topbar;
